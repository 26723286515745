<template>
  <Modal v-model="showpdf" :mask-closable="false" :scrollable="true" @on-visible-change="visibleChange"
    :footer-hide="true" width="1476">
    <div slot="header">下载报告</div>
    <div class="pdf-body" ref="detail">
      <div class="head">
        <!-- <img :src="headImg" class="img-head" alt="" srcset=""> -->
        <span>
          房屋智能估价结果
        </span>
        <!-- <img :src="headImg" class="img-head" style="transform: rotateZ(180deg)" alt="" srcset=""> -->
      </div>
      <!-- 估价结果 -->
      <div class="color-box">
      <div style="padding-top: 24px;">
        <div class="all-title">估价结果</div>

      </div>
        <div class="result">
          <Row :gutter="16" index="">
            <Col span="24">
              <div class="top">
              <div class="title">
                <span> {{ assessmentDetail.evlTitle }}</span>
                <div class="time">估价时间：{{ assessmentDetail.assessTime | filter_assessTime }}</div>

              </div>
              <div class="msg">
                <div class="address">{{ assessmentDetail.lpName }} {{ assessmentDetail.countyFullName }}</div>
              </div>
            </div>
            <div class="center-box">
              <div class="flex-box" v-if="(assessmentDetail.assessResult == 1)">
                <!-- <PriceBox :price="assessmentDetail.evaluateTotalPrice.toFixed(2)" unit="总价(万元）"></PriceBox>
                <PriceBox :price="assessmentDetail.evaluatePrice.toFixed(0)" unit="单价(元/㎡）" :isBlue="false">
                </PriceBox> -->
                <div class="price">
                  <span class="price1">总价 </span>
                  <span class="price-num"> {{ assessmentDetail.evaluateTotalPrice.toFixed(2) }} </span>
                  <span class="unit"> 万元</span>
                </div>
                <div class="price">
                  <span class="price1">单价 </span>
                  <span class="price-num"> {{ assessmentDetail.evaluatePrice.toFixed(0) }} </span>
                  <span class="unit"> （元/㎡）</span>
                </div>
              </div>
              <div class="flex-box" v-if="(assessmentDetail.assessResult == 3)">
                <!-- <PriceBox :price="assessmentDetail.evaluateTotalPrice.toFixed(2)" unit="参考总价(万元）"></PriceBox>
                <PriceBox :price="assessmentDetail.evaluatePrice.toFixed(0)" unit="参考单价(元/㎡）" :isBlue="false">
                </PriceBox> -->

                <div class="price">
                  <span class="price1">参考总价 </span>
                  <span class="price-num"> {{ assessmentDetail.evaluateTotalPrice.toFixed(2) }} </span>
                  <span class="unit"> 万元</span>
                </div>
                <div class="price">
                  <span class="price1">参考单价 </span>
                  <span class="price-num"> {{ assessmentDetail.evaluatePrice.toFixed(0) }} </span>
                  <span class="unit"> （元/㎡）</span>
                </div>


              </div>
              <div class="announcement" v-if="(assessmentDetail.evaluateRemark && assessmentDetail.assessResult == 3)">
                <img src="../../../assets/image/pledge/announcement.png" alt="">
                <span>{{ assessmentDetail.evaluateRemark }}</span>
              </div>
            </div>
            </Col>

            <!-- <Col span="24">
            <div class="BmapMin" id="BmapMin"></div>
            </Col> -->
          </Row>
        </div>
        <div>
        <Row>
         <Col span="24">
            <div class="BmapMin" id="BmapMin"></div>
            </Col>
        </Row>


        </div>

      </div>

      <!-- 参考实例 -->
      <div v-if="(exampleList.length > 0 || exampleListTow.length > 0)">
        <div class="all-title">参考实例</div>
        <div class="example">
          <div>
            <div class="ex-title">成交实例</div>
            <Table :columns="exampleColumns" v-if="exampleList.length > 0" :data="exampleList"></Table>
            <div class="no-data" v-else>
              <img :src="noDataImg" alt="">
              <div>暂无数据</div>
            </div>
          </div>
          <div>
            <div class="ex-title" style="margin-top: 48px;">挂牌实例</div>
            <Table :columns="exampleColumnsGuapai" v-if="exampleListTow.length > 0" :data="exampleListTow"></Table>
            <div class="no-data" v-else>
              <img :src="noDataImg" alt="">
              <div>暂无数据</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 周边配套 -->
      <div style="margin-top: 110px;" v-if="showSurround">
        <div class="all-title">周边配套</div>
        <div class="surround">
          <div v-for="(item) in surroundList" :key="item.name" style="margin-bottom: 36px;">
            <div v-if="item.list.length > 0">
              <div class="surround-title">{{ item.name }}</div>
              <div class="surround-detail">
                <span v-for="(chid, index) in item.list" :key="index">{{ chid.name
                  }}【{{ chid.detail_info.distance }}米】;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 使用声明 -->
      <div>
        <div class="statement">
          <div class="statement-title">房屋智能估价系统使用声明</div>

          <div class="statement-detail">
            <div class="title" style="margin: 20px 0;">
              尊敬的用户：
            </div>
            <div class="con">
              您好！感谢您使用由广东中思拓大数据研究院有限公司（简称中思拓研究院）开发的房屋智能估价系统（简称“中思拓房屋智能估价系统”或“本系统”、“系统”）进行房屋询价，中思拓房屋智能估价系统为您所查询的标的房屋提供当前市场价格参考。
            </div>
            <div class=" black">
              您在使用本系统询价前，须认真阅读并接受如下声明为前提：
            </div>
            <div class="con black">
              1.查询人和结果使用方须完全接受系统所设定的全部假设和限制条件为前提（特别需关注影响估价结论的因素及使用的限制要求）；
            </div>
            <div class="con black">
              2.系统根据您所提供的所需询价的标的物房屋的关键信息（座落、面积、层数等），参考当前市场价格参数，综合分析影响估价对象价值的主要因素，遵循独立、客观、公正、合法的原则，运用大数据估价方法，结合大数据模型算法和互联网大数据，在满足本次估价全部假设和限制条件下自动分析生成结果。
            </div>
            <div class="con black">
              3.本系统仅分析影响估价对象价值的主要因素，未考虑房屋室内因素（建设质量、装修等级、房屋维护、使用状况、房屋瑕疵等）、税费负担情况、权利限制情况（抵押担保、出租等）、和未知的不确定因素对分析结果的影响，并假定估价对象产权明晰，手续齐全、市场在询价期内保持稳定、可自由交易、查询人所提供的信息是准确的。

            </div>
            <div class="con black">
              4.我们并未对估价对象进行现场勘查，中思拓研究院不承担对估价对象的质量、现场情况、特殊情况及其他方面进行勘查、检测的责任。

            </div>
            <div class="con black">
              5.系统询价目的是根据大数据估算询价对象价值，了解、参考房屋的价值。未征得本系统所有者书面同意，使用者不得向其他单位和个人提供本系统的询价页面及询价结果的任何内容，禁止将询价页面及询价结果用于任何的商业用途。本系统的询价页面及询价结果不可被认为是对询价对象可实现价格的保证，不作为任何交易建议和交易估价的依据，中思拓研究院不承担相关当事人决策和交易的责任。

            </div>
            <div class="con">
              6.询价人应提供询价所需必要的房屋信息，并保证所提供信息的真实性、合法性、完整性，询价人及相关使用方应按本说明第5项限制的用途恰当使用本系统的询价页面及询价结果，并自行承担全部责任及风险；中思拓研究院不承担询价方和使用人使用本系统的询价页面及询价结果所产生的全部风险和责任。
            </div>
            <div class="con">
              7.估价时点应为查询当日，询价结果自出具之日起1个月内有效，在估价结果有效期内，估价对象状况或者住房市场发生明显变化的，应当进行相应调整。
            </div>
            <div class="con">
              8.未征得询价系统所有者书面同意，询价页面及询价结果全部或部分内容不得被摘抄、引用或披露于公开媒体，法律、政策规定以及相关当事方另有约定的除外。
            </div>
            <div class="con">
              9.系统所有者在法律许可范围内保留对分析数据和软件算法的最终解释权。
            </div>
            <div class="con black" style="text-align: right; color: #404040; font-weight: Medium;">
              广东中思拓大数据研究院有限公司
            </div>
          </div>

        </div>
      </div>

      <!-- 水印 -->
      <div class="watermark"></div>
    </div>
  </Modal>
</template>

<script>
import PriceBox from '@/views/pledge/components/PriceBox.vue'
import dayjs from 'dayjs'

import {
  land_data_retrieve
} from '@/api/reLand.js'
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { Row } from 'view-design';
export default {

  props: {
    pdfModel: {
      type: Boolean,
      required: true,
    },
    exampleList: {
      type: Array,
      default: () => ([

      ])
    },
    exampleListTow: {
      type: Array,
      default: () => ([

      ])
    },
    assessmentDetail: {
      type: Object,
      default: () => ({

      })
    },
    exampleColumns: {
      type: Array,
      default: () => ([
      ])
    },
    exampleColumnsGuapai: {
      type: Array,
      default: () => ([
      ])
    },
    showSurround: {
      type: Boolean,
      default: true
    },
    center: {
      type: Object,
      default: () => ({
        lng: 113.335593,
        lat: 23.119767,
      })
    },

  },
  components: {
    // PriceBox
  },
  watch: {
    center: {
      handler: function (val, oldVal) {
        this.mapData.center = val
      },
      deep: true
    },

  },
  data() {
    return {
      isCreaMap: false,

      liveIcon: require("@/assets/image/map/localNew.png"),
      headImg: require("@/assets/image/pledge/point-line.png"),
      // center: {
      //   lng: 113.335593,
      //   lat: 23.119767,
      // },
      //地图信息
      mapData: {
        zoom: 17,
        center: this.center
      },
      noDataImg: require("@/assets/image/pledge/no_data.png"),


      tabList: [{
        name: '教育',
        keyWorld: ['幼儿园', '小学', '中学', '高等院校', '科研机构', '图书馆', '科技馆', '亲子教育', '培训机构'],
      }, {
        name: '医疗',
        keyWorld: ['综合医院', '三甲医院', '专科医院', '药店', '疗养院', '体检机构', '急救中心', '疾控中心', '医疗保健'],
      }, {
        name: '商业',
        keyWorld: ['购物', '酒店', '美食', '休闲娱乐', '银行', '写字楼'],
      }, {
        name: '交通',
        keyWorld: ['地铁站', '公交站', '火车站', '长途汽车站', '飞机场', '港口', '停车场'],
      }, {
        name: '特殊',
        keyWorld: ['加油站', '液化气供应站', '垃圾场', '核电厂', '变电站', '殡仪服务', '化工厂', '危险品仓库', '屠宰场', '污水处理厂'],
      }],
      surroundList: [//后台获取的周边配套信息
        {
          name: '教育',
          list: [],
        },
        {
          name: '医疗',
          list: [],
        },
        {
          name: '商业',
          list: [],
        },
        {
          name: '交通',
          list: [],
        },
        {
          name: '特殊',
          list: [],
        },
      ],
    }
  },
  filters: {
    filter_assessTime(val) {
      return dayjs(val).format('YYYY-MM-DD');
    }
  },
  computed: {
    showpdf: {
      get() {
        return this.pdfModel
      },
      set(newVal) {
        this.$parent.showpdfModel = newVal
      }

    },
    houseStyle() {
      let roomCnt = this.assessmentDetail.roomCnt ? this.assessmentDetail.roomCnt : 0
      let parlourCnt = this.assessmentDetail.parlourCnt ? this.assessmentDetail.parlourCnt : 0
      if (roomCnt == 0) return '--'
      if (parlourCnt == 0) return `${roomCnt}房`
      return `${roomCnt}房${parlourCnt}厅`
    },

    HaveLiftStyle() {//电梯
      let isHaveLift = this.assessmentDetail.isHaveLift
      if (isHaveLift === '') return '--'
      if (isHaveLift) {
        return '有'
      } else {
        return '无'
      }
    },
  },

  mounted() {

  },
  methods: {
    async visibleChange(status) {//模态框状态改变
      if (status && !this.isCreaMap) {
        setTimeout(() => {
          this.loadMap(BMap)
          this.isCreaMap = true
        }, 500)
      }
      if (status) {

        this.$msg.success(
          {
            text: '报告已自动下载，请查看下载列表',
            duration: 3
          }
        )

        this.getSearchNearBy()

        setTimeout(() => {
          this.toImage()
        }, 1500)
      }
    },
    toImage() {
      // this.$btnlog(1015);
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.detail, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        // taintTest: false,
        imageTimeout: 0,
      }).then((canvas) => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        this.htmlToPdf(canvas);
      });
    },
    async htmlToPdf(htmlCanvas) {
      // 将canvas对象转为pdf
      const pdf = this.canvasToPdf(htmlCanvas);
      // 通过浏览器下载pdf
      this.downPdf(pdf, "估价报告");
    },
    downPdf(pdfInstance, title) {
      // 文件名过长导致下载失败
      if (title.length > 50) {
        title = title.substring(title.length - 50);
      }

      pdfInstance.save(title + ".pdf", {
        returnPromise: true
      }).then(() => {
        // 搜狗浏览器下载机制问题暂时不关闭
        // if (!(navigator.userAgent.toLowerCase().indexOf("se 2.x") > -1)) {
        //     setTimeout(window.close, 300);
        // }
      });
    },
    canvasToPdf(htmlCanvas) {
      const canvasWidth = htmlCanvas.width;
      const canvasHeight = htmlCanvas.height;
      const imgBase64 = htmlCanvas.toDataURL("image/jpeg", 1.0);

      // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 595.28;
      // 图片高度需要等比缩放
      const imgHeight = (595.28 / canvasWidth) * canvasHeight;

      let pageHeight = imgHeight; // pdf转化后页面总高度
      let position = 0;

      const pdfInstance = new jsPdf("", "pt", "a4");
      pdfInstance.setFontSize(12);

      if (imgHeight < 841.89) {
        pdfInstance.addImage(imgBase64, "JPEG", 0, 0, imgWidth, imgHeight);
      } else {
        while (pageHeight > 0) {
          pdfInstance.addImage(
            imgBase64,
            "JPEG",
            0,
            position,
            imgWidth,
            imgHeight
          );
          pageHeight -= 841.89;
          position -= 841.89;
          if (pageHeight > 0) {
            pdfInstance.addPage();
          }
        }
      }

      return pdfInstance;
    },

    loadMap(BMap) {//地图打点
      let that = this;
      that.map = new BMap.Map("BmapMin");
      const myPoint = new BMap.Point(
        that.mapData.center.lng,
        that.mapData.center.lat
      );
      // that.map.centerAndZoom(myPoint, 7);
      // 初始化地图， 设置中心点坐标和地图级别
      that.map.centerAndZoom(myPoint, that.mapData.zoom);
      that.map.disableDragging();     //禁止拖拽
      that.map.disableScrollWheelZoom();//禁止缩放
      const icon = new BMap.Icon(that.liveIcon, new BMap.Size(34, 58), {
        anchor: new BMap.Size(25, 60)
      });
      icon.setSize(new BMap.Size(34, 58));
      icon.setImageSize(new BMap.Size(34, 58));
      let marker = new BMap.Marker(myPoint, {
        icon,
      });
      marker.addEventListener("click", () => {
        that.map.centerAndZoom(myPoint, 17);
      });
      that.map.addOverlay(marker);

    },

    getSearchNearBy() {//循环查询周边配套
      // this.surroundList = []
      this.tabList.map(item => {
        this.searchNearBy(item.name, item.keyWorld)

      })
    },
    //查询周边配套
    async searchNearBy(name, keyWorld) {
      let {
        lat,
        lng
      } = this.center
      let params = {
        radius: 3000,
        location: `${lat},${lng}`,
        query: keyWorld.join(','),
        // tag: keyWorld.join(','),
        page_num: 0,
        page_size: 20,
        filter: 'sort_name:distance|sort_rule:1',
        radius_limit: true,
        scope: 2
      }
      let data = await land_data_retrieve(params)
      let results = data.results.slice(0, 10)
      let obj =
      {
        name: name,
        list: results
      }

      this.surroundList = this.surroundList.map(item => {
        if (item.name == name) {
          item.list = results
        }
        return item
      })
      // this.markList = []
      // data.results.forEach((item, index) => {
      //   this.markList.push(item)
      // })
    },

  }
}
</script>

<style scoped lang="scss">
.pdf-body {
  width: 1440px;
  // background: #F1F6FF;
  background: #FFFFFF;
  padding-bottom: 24px;

  // height: 900px;
  // overflow-y: scroll;
  //头部
  .head {
    width: 100%;
    // height: 197px;
    // background: #0077FF;
    font-size: 48px;
    font-family: PingFang SC-Bold, PingFang SC;
    // font-weight: bold;
    // color: #FFFFFF;
    color: #0077FF;
    text-align: center;
    // line-height: 197px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #0077FF solid 4px;
    padding-bottom: 38px;
    margin-bottom: 30px;
    .img-head {
      margin: 0 24px;
    }

    //  &:before {
    // content: "";
    //   background-image: url(~@/assets/image/pledge/point-line.png);

    // }
  }

  .all-title {
    width: 143px;
    // margin-top: 68px;
    margin-left: 24px;
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    text-align: right;
    border-left: 7px solid #0077FF;

  }

  .color-box {
    background: rgba(0, 119, 255, 0.04);

  }

  //估价结果
  .result {
    padding: 24px;
    width: 96%;
    // height: 487px;
    // margin: 30px auto;
    // background-color: #fff;
    // border-radius: 10px 10px 10px 10px;

    .top {
      .title {
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #404040;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .msg {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;

        .address {
          color: #737373;
          ;

        }
      }

    }

    .center-box {
      // padding-left: 24px;
      padding-right: 60px;
      width: 100%;
      margin-top: 18px;
      // height: 343px;
      // background: #F7F8FA;
      border-radius: 4px 4px 4px 4px;
      padding-top: 24px;

      .flex-box {
        display: flex;
        // justify-content: space-between;
    margin-bottom: 30px;
        .price {
          margin-right: 100px;

          .price1 {
            font-size: 24px;
            color: #404040;
            margin-right: 22px;
          }

          .price-num {
            font-family: D-DIN;
            font-size: 40px;
            font-weight: bold;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0em;
            margin-right: 8px;

            font-variation-settings: "opsz" auto;
            color: #0077FF;

          }

          .unit {
            font-size: 24px;

            color: #737373;
          }
        }

        .right-box {
          display: flex;
          flex-direction: column-reverse;
          // justify-content: center;

          width: 120px;

          .top-bit {
            margin-top: 32px;
          }
        }
      }
    }



    .announcement {
      margin-top: 20px;
      font-size: 16px;
      display: flex;
      // align-items: center;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #737373;

      img {
        width: 17px;
        height: 17px;
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .time {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #737373;
      // margin-left: 22px;
      // margin-top: 24px;
    }



  }
    #BmapMin {
      height: 499px;
      // min-width: 748px;
      width: 100%;
      background: #eee;
    }
  //详情
  .detail {
    // padding: 24px;
    // margin: 30px auto;

    width: 96%;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #737373;
    height: 188px;
    // background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;

    .item {
      display: flex;
      margin-top: 24px;
      margin-bottom: 48px;

      // align-items: center;
      // justify-content: space-evenly;
      .box {
        margin-right: 88px;
        width: 240px;
        display: flex;

        .msg {
          width: 66px;
          margin-right: 16px;
        }

        .units {
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #404040;
        }
      }

    }

  }

  // 参考案例
  .example {
    padding: 24px;
    width: 96%;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 98px;
    height: 664px;
    // height: 328px;
    background: #FFFFFF;
    border-radius: 4px;

    .ex-title {
      width: 100%;
      height: 40px;
      text-align: left;
      font-size: 16px;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      color: #404040;
      border-bottom: 1px solid #E7E7E7;
      margin-bottom: 22px;
    }

    .no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 250px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #A6A6A6;
    }

  }

  //周边配套
  .surround {
    padding: 24px;
    width: 96%;
    margin: auto;
    margin-top: 30px;

    // height: 381px;
    // height: 328px;
    height: 622px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;

    .surround-title {
      color: #262626;
      font-weight: bold;
    }

    .surround-detail {

      color: #404040;

      span {
        margin-right: 10px;
      }
    }
  }

  // 使用声明

  .statement {
    padding: 24px;
    margin-top: 62px;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #737373;

    .statement-title {
      font-size: 20px;
      font-family: PingFang SC-Bold;
      font-weight: bold;
      color: #262626;
    }

    .statement-detail {
      .con {
        text-indent: 2em;
      }
    }
  }

  //水印
  .watermark {
    position: absolute;
    top: 463px;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('../../../assets/image/watermarkNew.png') repeat-y;
    // background-size: 100% 100%;
    height: 80%;
    width: 100%;
    background-position: center bottom;
    pointer-events: none;
    z-index: 99999;
    // background-color: #007882;
  }
}
</style>
